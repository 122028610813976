
import Vue from 'vue'
import { getOptions } from '../../mixins/formData'

export default Vue.extend({
  name: 'AwardList',

  data: () => {
    return {
      activeFilters: {} as any,
      ausgewaehlt: false,
      award: null as any,
      eingereicht: false,
      hasQuery: false,
      isLoading: true,
      items: [] as any[],
      limit: 10,
      orderBy: '',
      orderOptionsAwards: [
        {id: 0, value: 'id', name: 'ID'},
        {id: 1, value: 'start', name: 'Beginn'},
        {id: 2, value: 'title', name: 'Titel'},
        {id: 3, value: 'year', name: 'Jahr'},
      ],
      orderOptionsProjects: [
        {id: 0, value: 'projectDetails.ident', name: 'Ident'},
        {id: 1, value: 'titel', name: 'Titel'},
        {id: 2, value: 'awardtype', name: 'Kampagnenart'},
        {id: 3, value: 'fachbereich', name: 'Fachbereich'},
        {id: 4, value: 'ort', name: 'Ort'},
        {id: 5, value: 'typologie', name: 'Typologie'},
        {id: 6, value: 'userId', name: 'Benutzer ID'},
      ],
      orderOptionsSponsors: [
        {id: 0, value: 'id', name: 'ID'},
        {id: 1, value: 'name', name: 'Name'},
      ],
      orderOptionsUsers: [
        {id: 0, value: 'id', name: 'ID'},
        {id: 1, value: 'firstname', name: 'Vorname'},
        {id: 2, value: 'lastname', name: 'Nachname'},
        {id: 3, value: 'email', name: 'E-Mail'},
      ],
      page: 1 as any,
      press: false,
      zustimmung: false,
      energieeffizient: false,
      besichtigungstermin: false,
      postkarten: false,
      imported: false,
      importedWithoutTypologie: false,
      query: '',
      sort: '',
      sortOptions: [
        {id: 0, value: 'desc', name: 'absteigend'},
        {id: 1, value: 'asc', name: 'aufsteigend'},
      ],
      typeHeader: {
        awards: {
          _header: true,
          id: 'ID',
          title: 'Titel',
          awardType: 'Kategorie',
          year: 'Jahr',
          active: 'Aktiv',
          related: {
            projekteCount: 'Projekte',
          },
        },
        projects: {
          _header: true,
          titel: 'Titel',
          baujahr: 'Baujahr',
          bauherr_name: 'Bauherr',
          reg_bezirk: 'Regierungsbezirk',
          typologie: 'Typologie',
          userId: 'Benutzer',
        },
        sponsors: {
          _header: true,
          id: 'ID',
          name: 'Name',
        },
        users: {
          _header: true,
          id: 'ID',
          firstname: 'Vorname',
          lastname: 'Nachname',
          email: 'E-Mail',
          related: {
            // office: { name: 'Büro' },
            roles: { name: 'Rechte' },
            projekte: { length: 'Projekte' },
          },
        },
      } as any,
      website: false,
    }
  },

  computed: {
    appInfo() {
      return this.$store.state.appInfo
    },
    awards () {
      return this.$store.state.awards?.awards
    },
    allAwards () {
      return this.$store.state.awards?.allAwards
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    crudListParams: {
      get () {
        return this.$store.state.crudListParams
      },
      set (obj: any) {
        return this.$store.commit('setCrudListParams', obj)
      },
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    selectedAward () {
      return this.$store.state.awards?.allAwards.filter((a: any) => a.id == this.award)?.at(0)
    },
    currentAdminFilter () {
      if (![null, 'null'].includes(this.award)) {
        const filterAward: any[] = this.$store.state.awards?.awards.filter((a: any) => a.id == this.award)
        if (filterAward?.length) {
          const { awardType, year } = filterAward[0]
          const conf = this.$store.state.appInfo.awards.awardConfiguration[awardType][year]
          if (conf) {
            return conf.adminFilter
          }
        }
      }
      return this.$store.state.defaultAdminFilter
    },
    orderOptions () {
      switch (this.$route.params.type) {
        case 'awards':
          return this.orderOptionsAwards
        case 'sponsors':
          return this.orderOptionsSponsors
        case 'users':
          return this.orderOptionsUsers
        default:
          return this.orderOptionsProjects
      }
    },
    titles () {
      return this.$store.state.titles
    },
    type () {
      return this.$route.params.type
    },
    typeCount () {
      switch (this.$route.params.type) {
        case 'awards':
          return this.$store.state.awards.awardCount
        case 'sponsors':
          return this.$store.state.sponsors.sponsorCount
        case 'users':
          return this.$store.state.users.userCount
        default:
          return this.$store.state.projects.projectCount
      }
    },
    users () {
      return this.$store.state.users.users
    },
  },

  methods: {
    activeFilterChanged(name: string, value: any) {
      // console.log('#### activeFilterChanged ', name, '->', value);
      const { activeFilters } = this
      const changedFilters = Object.assign({}, activeFilters)
      const filterValue = changedFilters[name]
      if (filterValue === value) {
        delete changedFilters[name]
      } else {
        changedFilters[name] = value;
      }
      this.activeFilters = Object.assign({}, changedFilters)
    },

    filterList () {
      this.isLoading = true
      const { query, orderBy, sort, award, page, activeFilters, type } = this
      const filters = Object.entries(activeFilters)
      let res = '/' + page
      let params = []
      if (query || orderBy || sort || award || filters.length >= 1 ) res += '?'
      if (query) params.push('q=' + query)
      if (orderBy) params.push('orderBy=' + orderBy)
      if (sort) params.push('orderMethod=' + sort)

      for (const [key, value] of filters) {
        const val = value === true ? 1 : value
        if (value) params.push(`${key}=${val}`)
      }
      if (award) {
        let a = this.allAwards.filter((aw: any) => aw.id == award)[0]
        if (a) {
          params.push('awardType=' + a.awardType)
          params.push('year=' + a.year)
        }
      }
      res += params.join('&')

      // update route
      if (type == 'projects' && this.$route.path !== '/admin/liste/projects/' + award + '/' + page) {
        this.$router.replace('/admin/liste/projects/' + award + '/' + page)
      }
      const listType = this.type
      this.crudListParams = {
        query,
        sort,
        orderBy,
        page,
        listType,
        award,
        activeFilters,
      }
      this.init(res)
    },

    gotoPage (i: any) {
      console.log('gotoPage: ', i);
      this.page = i
      this.filterList()
    },

    init (query: string = '') {
      this.isLoading = true
      let ready = 0
      const go = (res: any) => {
        if (++ready === 1) {
          // requests done
          if (res && res.items) {
            // console.log(' ...ok');
            this.items = res.items
          }
          this.isLoading = false
          if (query.length > 0) this.hasQuery = true
        }
      }

      switch (this.type) {
        case 'awards':
          this.$store.dispatch('getAwards', query).then(go)
          break
        case 'sponsors':
          this.$store.dispatch('getSponsors', query).then(go)
          break
        case 'users':
          this.$store.dispatch('getUsers', query).then(go)
          break
        default:
          this.$store.dispatch('getProjects', query).then(go)
          break
      }
    },

    switchContext (val: any) {
      console.log('switchContext: ', val);
      if (!val || val === 'null' || (this.currentAward && val === this.currentAward.id)) {
        this.$store.commit('setCurrentAward', null)
        return this.filterList()
      }

      this.award = val
      let res = this.awards?.filter((aw: any) => aw.id == this.award)[0]
      this.$store.commit('setCurrentAward', res)
      return this.filterList()
    },

    getOptions,
  },

  created () {
    const {
      query,
      orderBy,
      sort,
      page,
      listType,
      award,
      activeFilters,
    } = this.crudListParams

    if (!this.awards || this.awards.length < 1) {
      this.$store.dispatch('getAwards')
    }
    if (
      this.$route.params.page &&
      this.$route.params.page != this.page &&
      typeof this.$route.params.page !== 'undefined'
    ) {
      this.page = this.$route.params.page
    } else if (page != this.page) {
      this.page = page || 1
    }

    if (activeFilters && Object.entries(activeFilters).length > 0) this.activeFilters = activeFilters
    if (query && !this.query && this.type === listType) this.query = query
    if (orderBy && !this.orderBy) this.orderBy = orderBy
    if (sort && !this.sort) this.sort = sort

    if (this.$route.params.awardId && this.$route.params.awardId !== 'null') {
      this.award = this.$route.params.awardId
      this.switchContext(null)
    } else {
      if (!this.award) {
        this.award = award
      } else if (this.currentAward?.id) {
        this.award = this.currentAward.id
      }
      this.filterList()
    }
  },

  watch: {
    '$route.params.awardId' (to, from) {
      // console.log('awardId to: ', to);
      if (from !== to) {
        this.page = 1
        // this.switchContext(to)
        this.$store.commit('setListAward', to)
        this.activeFilters = {}
        this.filterList()
      }
    },
    '$route.params.page' (to, from) {
      // console.log('page to: ', to);
      if (from != to && to != this.page && typeof to !== 'undefined') {
        this.page = to
        this.filterList()
      }
    },
    activeFilters(to, from) {
      // console.log(' --> activeFilters from !== to: ', JSON.stringify(from) !== JSON.stringify(to));
      if (JSON.stringify(from) !== JSON.stringify(to)) {
        this.page = 1
        this.filterList()
      }
    },
  },
})
